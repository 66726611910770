import React, { useContext, useState, useEffect } from 'react';

import { claimCandy, readNumberCandyClaimed } from '../utils/interact';
import { WalletContext } from '../context/wallet';
import allowlist from '../allowlist/candy_claim.json';

import { Button, Container, Image, Row, Col } from 'react-bootstrap';
import { PlusCircle, DashCircle } from 'react-bootstrap-icons';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import image1 from '../images/152.png';
import image2 from '../images/219.png';
import image3 from '../images/249.png';
import image4 from '../images/259.png';
import image5 from '../images/475.png';
import image6 from '../images/485.png';
import image7 from '../images/492.png';
import image8 from '../images/535.png';
import image9 from '../images/649.png';
import image10 from '../images/676.png';
import guava from '../images/guava.png';
import chogger from '../images/chogger.png';
import rareImage1 from '../images/paper 1_1.png';
import rareImage2 from '../images/Devil-1_1.gif';
import rareImage3 from '../images/Radioactive 1_1.png';
import rareImage4 from '../images/rainbow-1_1.gif';
import traits from '../images/traits.png';
import roadmap from '../images/roadmap.png';

const HomepageClaimCandy = () => {
  const { address } = useContext(WalletContext);

  const [numberToMint, setNumberToMint] = useState(1);
  const [maxCanClaim, setMaxCanClaim] = useState(1);
  const [numberCandyClaimed, setNumberCandyClaimed] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [result, setResult] = useState('');

  useEffect(() => {
    //find and update from claim allowlist
    if (address) {
      let index = allowlist.findIndex(
        (item) => item.address.toLowerCase() == address.toLowerCase()
      );

      if (index == -1) {
        setMaxAmount('-1');
      } else {
        setMaxAmount(allowlist[index].maxAmount);
      }
    }

    //fetch number already claimed
    async function fetchNumberOfCandyClaimed() {
      let numberCandyClaimed = await readNumberCandyClaimed(address);
      setNumberCandyClaimed(numberCandyClaimed);
    }

    if (address) {
      fetchNumberOfCandyClaimed();
    }
    //auto set number to mint to max for each user once address loads
    if (address) {
      setNumberToMint(parseInt(maxAmount) - parseInt(numberCandyClaimed));
    }
  }, [address, maxAmount, numberCandyClaimed]);

  const increment = () => {
    setNumberToMint(numberToMint + 1);
  };

  const decrement = () => {
    setNumberToMint(Math.max(numberToMint - 1, 1));
  };

  const handleClick = async () => {
    const resultOfClaim = await claimCandy(address, numberToMint, maxAmount);
    setResult(resultOfClaim.status);
  };

  let markup = <div></div>;
  if (address && maxAmount != '-1') {
    markup = (
      <div className='d-flex flex-column freelanding-inputWrapper'>
        <div className='freelanding-innerWrapper'>
          <div className='freelanding-info-text text-center'>
            <span className='freelanding-numberCanMint'>{maxAmount}</span> candy
            available for this wallet. you have already claimed{' '}
            <span className='freelanding-numberUserHasMinted'>
              {numberCandyClaimed}
            </span>{' '}
            candy. you may claim{' '}
            <span className='freelanding-numberLeft'>
              {maxAmount - numberCandyClaimed}
            </span>{' '}
            more candy.
            <div className='text-center mt-3'>price: free (just pay gas)</div>
          </div>
          <div className='d-flex flex-column align-items-center mx-auto'>
            <div className='d-flex align-items-center mt-2'>
              <DashCircle
                className='freelanding-icons'
                size={36}
                onClick={decrement}
              />
              <div className='freelanding-numberToMint'>{numberToMint}</div>
              <PlusCircle
                className='freelanding-icons'
                size={36}
                onClick={increment}
              />
            </div>
            {parseInt(numberCandyClaimed) < parseInt(maxAmount) ? (
              <Button
                size='lg'
                className='landing-mintButton mt-2'
                variant='success'
                onClick={() => handleClick()}
              >
                claim candy!
              </Button>
            ) : (
              <Button
                size='lg'
                className='disabledButton mt-2'
                variant='success'
                disabled
              >
                you have no more candy left to cliam
              </Button>
            )}
          </div>
          <div className='text-center mt-4'>{result}</div>
        </div>
      </div>
    );
  } else if (address && maxAmount == '-1') {
    markup = (
      <div className='d-flex flex-column freelanding-inputWrapper'>
        <div className='freelanding-innerWrapper'>
          <div className='freelanding-info-text text-center'>
            sorry, you're not eligible to claim candy
          </div>
          <div className='d-flex flex-column align-items-center mx-auto'>
            <div className='d-flex align-items-center mt-2'>
              <DashCircle
                className='freelanding-icons'
                size={36}
                onClick={decrement}
              />
              <div className='freelanding-numberToMint'>{numberToMint}</div>
              <PlusCircle
                className='freelanding-icons'
                size={36}
                onClick={increment}
              />
            </div>
            <Button
              size='lg'
              className='disabledButton mt-2'
              variant='success'
              disabled
            >
              no candy to claim
            </Button>
          </div>
          <div className='text-center mt-4'>{result}</div>
        </div>
      </div>
    );
  } else {
    markup = (
      <div className='d-flex flex-column freelanding-inputWrapper'>
        <div className='d-flex flex-column freelanding-innerWrapper align-items-center justify-content-center'>
          <div className='freelanding-info-text text-center'>
            connect wallet to start claiming your candy
          </div>
          <Button
            size='lg'
            className='disabledButton mt-2'
            variant='success'
            disabled
          >
            claim
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* landing */}
      <Container fluid className='freelanding d-flex'>
        <div className='freelanding-wrapper d-flex justify-content-center'>
          <div className='d-flex flex-column align-items-center justify-content-center'>
            {/* <Image className='freelanding-image' src={logo} /> */}
            {/* <h1 className='freelanding-heading text-center'>doh doh diaries</h1> */}
            <div className='freelanding-mintingPhase text-center'>
              claim your candy!
            </div>

            {markup}
          </div>
        </div>
      </Container>
      <Container fluid className='mintDetails'>
        <Container className='d-flex flex-column align-items-center justify-content-center'>
          <Row className='mintDetails-row'>
            <div className='mintDetails-heading text-center'>
              candy claim information:
            </div>
          </Row>

          {/* mint details large and above */}
          <Row className='d-flex flex-column mintDetails-text-wrapper'>
            <div className='mintDetails-text-info text-center'>
              <span className='mintDetails-text-bold'>what is candy?:</span>{' '}
              candy is the currency of doh doh diaries. they are erc-1155 tokens
              that can be traded for items in the doh doh shop (coming soon).
            </div>
            <div className='mintDetails-text-info text-center'>
              <span className='mintDetails-text-bold'> how many</span>: doh doh
              holders may claim 1 candy per doh doh they held at the time of the
              snapshot.
            </div>
            <div className='mintDetails-text-info text-center'>
              <span className='mintDetails-text-bold'>snapshot</span>: snapshot
              was taken August 25th, 2022.
            </div>
          </Row>
        </Container>
      </Container>
      {/* about 1 */}
      <Container fluid className='about-1'>
        <Row className='about-row'>
          <Col lg={{ span: 6, order: 2 }}>
            <div className='about-image-container'>
              <Row>
                <Col>
                  <Image className='about-image' src={image1} />
                </Col>
                <Col>
                  <Image className='about-image' src={image3} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Image className='about-image' src={image4} />
                </Col>
                <Col>
                  <Image className='about-image' src={image2} />
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={{ span: 6, order: 1 }} className='d-flex align-items-center'>
            <div className='about-text-1 text-center'>
              <p>
                'doh doh' comes from the expression of making mistakes or the
                equivalent of pooping your pants. we want to create a fun,
                relatable collection that represents everyone within the nft
                space.
              </p>
              <p>
                the word 'diary' is because we feel that life's best moments are
                looking back at your doh dohs.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* traits */}
      <Container
        fluid
        className='traits d-flex flex-column justify-content-between'
      >
        <Row className='d-flex traits-heading text-center'>
          <p className='traits-heading'>
            the art of doh doh diaries is inspired from old school video games
            and tv shows - a mix of nostalgia and humor. all traits are
            hand-drawn and designed to be unique, so everyone can find the
            perfect doh doh for themselves.
          </p>
        </Row>
        <Row className='d-flex'>
          <Image className='traits-image mx-auto' src={traits} />
        </Row>
      </Container>
      {/* about 2 */}
      <Container fluid className='about-2'>
        <Row className='about-row'>
          <Col lg={{ span: 6, order: 1 }}>
            <div className='about-image-container'>
              <Row>
                <Col>
                  <Image className='about-image' src={image5} />
                </Col>
                <Col>
                  <Image className='about-image' src={image6} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Image className='about-image' src={image7} />
                </Col>
                <Col>
                  <Image className='about-image' src={image8} />
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={{ span: 6, order: 2 }} className='d-flex align-items-center'>
            <p className='about-text text-center'>
              some doh dohs will have rare traits such as bunny ears, vr
              headsets, animal onesies, and more!
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className='about-3'>
        <Row className='about-row'>
          <Col lg={{ span: 6, order: 2 }}>
            <div className='about-image-container'>
              <Row>
                <Col>
                  <Image className='about-image' src={rareImage4} />
                </Col>
                <Col>
                  <Image className='about-image' src={rareImage3} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Image className='about-image' src={rareImage1} />
                </Col>
                <Col>
                  <Image className='about-image' src={rareImage2} />
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={{ span: 6, order: 1 }} className='d-flex align-items-center'>
            <p className='about-text text-center'>
              we have also created some ultra rare 1/1s or doh/dohs with unique
              properties (0.1% of the whole collection)!
            </p>
          </Col>
        </Row>
      </Container>
      {/* roadmap */}
      <Container fluid className='roadmap'>
        <Row className='roadmap-row'>
          <div className='roadmap-heading text-center'>roadmap</div>
        </Row>
        <Row className='roadmap-image-row'>
          <Image src={roadmap} className='roadmap-image-image mx-auto'></Image>
        </Row>
        <Image src={image9} className='roadmap-image-doh1'></Image>
        <Image src={image10} className='roadmap-image-doh2'></Image>
      </Container>
      <Container fluid className='team-outer'>
        <Container className='team'>
          <Row className='team-heading text-center'>
            <h2 className='team-heading'>Team</h2>
          </Row>
          <div className='team-content d-flex flex-wrap justify-content-around'>
            <div className='team-column text-center'>
              <div className='team-image-wrapper'>
                <Image className='team-image mx-auto' src={guava} />
              </div>
              <div>
                <div className='team-text text-center d-flex flex-column'>
                  <a href='https://twitter.com/eat_guava'>@eat_guava</a>
                  <p>art, dev, founder</p>
                </div>
              </div>
            </div>
            <div className='team-column text-center'>
              <div className='team-image-wrapper'>
                <Image className='team-image mx-auto' src={chogger} />
              </div>
              <div>
                <div className='team-text text-center d-flex flex-column'>
                  <a href='https://twitter.com/chubbychogger'>
                    @chubby_chogger
                  </a>
                  <p>ops, marketing, strategy</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default HomepageClaimCandy;
