import './stylesheets/App.css';

import { WalletProvider } from './context/wallet';

import Homepage from './pages/Homepage';
import HomepageFreeMint from './pages/HomepageFreeMint';
import HomepagePaidMint from './pages/HomepagePaidMint';
import HomepageAllowlistMint from './pages/HomepageAllowlistMint';
import HomepageClaimCandy from './pages/HomepageClaimCandy';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import './stylesheets/App.css';

const App = () => {
  return (
    <WalletProvider>
      <Navbar />
      {/* <Homepage /> */}
      {/* <HomepageFreeMint /> */}
      {/* <HomepagePaidMint />
      <HomepageAllowlistMint /> */}
      <HomepageClaimCandy />
      <Footer />
    </WalletProvider>
  );
};

export default App;
