import React from 'react';
import { useEffect, useContext } from 'react';

//user imports
import { ellipseAddress, getChainData } from '../utils/utilities';
import { WalletContext } from '../context/wallet';
import Web3Modal from 'web3modal';
import WalletLink from 'walletlink';
import WalletConnectProvider from '@walletconnect/web3-provider';

import LogoWords from '../images/logo_words.png';

//bootstrap imports
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { Discord, Twitter } from 'react-bootstrap-icons';
import { ReactComponent as OpenseaSVG } from '../images/opensea.svg';
import EtherscanLogo from '../images/etherscan-logo-circle.png';

const INFURA_ID = process.env.REACT_APP_INFURA_KEY;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  'custom-walletlink': {
    display: {
      logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
      name: 'Coinbase',
      description: 'Connect to Coinbase Wallet (not Coinbase App)',
    },
    options: {
      appName: 'Coinbase', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      // networkUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
};

export let web3Modal;

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  });
}

const Menubar = () => {
  const {
    provider,
    web3Provider,
    address,
    chainId,
    connect,
    disconnect,
    dispatch,
  } = useContext(WalletContext);

  // Auto connect to the cached provider
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      console.log(web3Modal.cachedProvider);
      connect();
    }
  }, [connect]);

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        // eslint-disable-next-line no-console
        console.log('accountsChanged', accounts);
        dispatch({
          type: 'SET_ADDRESS',
          address: accounts[0],
        });
      };

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId) => {
        window.location.reload();
      };

      const handleDisconnect = (error) => {
        // eslint-disable-next-line no-console
        console.log('disconnect', error);
        disconnect();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider, disconnect]);

  const chainData = getChainData(chainId);

  return (
    <>
      <Navbar className='navbar' expand='lg'>
        {
          <Navbar.Brand className='d-flex'>
            <Image className='navbar-logo' src={LogoWords} />
          </Navbar.Brand>
        }

        <Nav className='d-flex align-items-center justify-content-center ms-auto'>
          <div className='navbar-socialLinks'>
            <a
              href='https://twitter.com/dohdohdiaries'
              target='_blank'
              rel='noreferrer'
            >
              <Twitter className='navbar-social-icons' size={34} />
            </a>
            <a
              href='https://discord.com/invite/gbZhNNpBZx'
              target='_blank'
              rel='noreferrer'
            >
              <Discord className='navbar-social-icons' size={34} />
            </a>
            <a
              href='https://etherscan.io/address/0x912524ed3bba1038d76c1e4742b7da448f127113'
              target='_blank'
              rel='noreferrer'
            >
              <Image src={EtherscanLogo} className='navbar-social-icons' />
            </a>
            <a
              href='https://opensea.io/collection/doh-doh-diaries'
              target='_blank'
              rel='noreferrer'
            >
              <OpenseaSVG className='navbar-social-icons openseaNav' />
            </a>
          </div>
          <div className='d-flex flex-column justify-content-center navbar-wallet-wrapper'>
            <div>{chainData?.name}</div>
            <div>{ellipseAddress(address)}</div>
          </div>
          {web3Provider ? (
            <Button
              className='navbar-connectButton'
              type='button'
              onClick={(web3Modal) => disconnect(web3Modal)}
            >
              Disconnect Wallet
            </Button>
          ) : (
            <Button
              className='navbar-connectButton'
              type='button'
              onClick={(web3Modal) => connect(web3Modal)}
            >
              Connect Wallet
            </Button>
          )}
        </Nav>
      </Navbar>
    </>
  );
};

export default Menubar;
