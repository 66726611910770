import React from 'react';
import { Image, Row } from 'react-bootstrap';
import { Discord, Instagram, Twitter } from 'react-bootstrap-icons';
import { ReactComponent as OpenseaSVG } from '../images/opensea.svg';
import EtherscanLogo from '../images/etherscan-logo-circle.png';

export default function Footer() {
  return (
    <section className='footer'>
      <div className='footer--wrapper d-flex flex-column align-items-center justify-content-center'>
        <Row className='footer--bottom d-flex flex-column align-items-center justify-content-center'>
          <div className='footer--social d-flex justify-content-center align-items-center'>
            <a
              href='https://twitter.com/dohdohdiaries'
              target='_blank'
              rel='noreferrer'
            >
              <Twitter className='footer--social--icons' size={28} />
            </a>
            <a
              href='https://discord.com/invite/gbZhNNpBZx'
              target='_blank'
              rel='noreferrer'
            >
              <Discord className='footer--social--icons' size={34} />
            </a>
            <a
              href='https://discord.com/invite/gbZhNNpBZx'
              target='_blank'
              rel='noreferrer'
            >
              <Image src={EtherscanLogo} className='footer--social--icons' />
            </a>
            <a href='https://opensea.io' target='_blank' rel='noreferrer'>
              <OpenseaSVG className='footer--social--icons footerOpenseaNav' />
            </a>
          </div>
          <div className='footer--copyright d-flex justify-content-center'>
            © Copyright {new Date().getFullYear()} Doh Doh Diaries
          </div>
        </Row>
      </div>
    </section>
  );
}
