import allowlist from '../allowlist/candy_claim.json';

const keccak256 = require('keccak256');
const { MerkleTree } = require('merkletreejs');
const Web3 = require('web3');

const allLower = allowlist.map((item) => item);

//make leaf nodes from address and amount (string)
const leafNodes = allLower.map((addr) =>
  Web3.utils.soliditySha3(
    { t: 'address', v: Web3.utils.toChecksumAddress(addr.address) },
    { t: 'string', v: addr.maxAmount }
  )
);

const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

export const getProof = (addr, amount) => {
  // console.log(addr, amount);
  // console.log(Web3.utils.isAddress(addr));
  // console.log(Web3.utils.isAddress(allLower[0].address));
  // console.log(addr, allLower[0].address);
  let index = allLower.findIndex(
    (item) =>
      Web3.utils.toChecksumAddress(item.address) ===
      Web3.utils.toChecksumAddress(addr)
  );
  //   console.log('leafNode: ', leafNodes[index].toString('hex'));
  return merkleTree.getHexProof(leafNodes[index].toString('hex'));
};

// Provide amount as a string
// const proof = getProof('0x63fAE0b0D819A607C57226Ce001d79d98235A9d3', '20');

// console.log('Root : ', merkleTree.getHexRoot());
// Root :  0x399f97e5a37a31d24b746bb2d8fa5212fbe3c0f7ceb4b69ce3c9f37d0379ff72

// console.log('Proof : ', proof);
// // Proof :  ['0x15e7001d27767868de62fcb73e3d14ac4d28c60dda34b0f259e79440b8f27e36']
