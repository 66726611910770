const { getProof } = require('../utils/merkleproof');

// require('dotenv').config();

const infuraKey = process.env.REACT_APP_INFURA_KEY;

const contractABI = require('../abi/contract-abi.json');
// const contractAddress = '0xBB3DBCb4B9810cc5b7D201E33E67b961ABC6B98d'; //rinkeby
// const contractAddress = '0x912524ed3bba1038d76C1e4742b7dA448F127113'; //mainnet
const contractAddress = '0x793B1a5200037f47b55D2fE7fe3943492D9d5ae0'; //mainnet candy shop
const Web3 = require('web3');

// const web3 = new Web3(`https://rinkeby.infura.io/v3/${infuraKey}`);

const web3 = new Web3(`https://mainnet.infura.io/v3/${infuraKey}`);

//read how many have been minted
export const readTotalSupply = async () => {
  //load smart contract
  let contract = await new web3.eth.Contract(contractABI, contractAddress);

  try {
    const totalSupply = await contract.methods.totalSupply().call();
    return totalSupply;
  } catch (error) {
    return {
      success: false,
      status: 'Something went wrong: ' + error.message,
    };
  }
};

//read number of free mints a person has minted
export const readNumberFreeMinted = async (_address) => {
  //load smart contract
  let contract = await new web3.eth.Contract(contractABI, contractAddress);

  try {
    const numberFreeMinted = await contract.methods
      .alreadyMintedFree(_address)
      .call();
    return numberFreeMinted;
  } catch (error) {
    return error.message;
  }
};

//read number of paid mints a person has minted
export const readNumberPaidMinted = async (_address) => {
  //load smart contract
  let contract = await new web3.eth.Contract(contractABI, contractAddress);

  try {
    const numberFreeMinted = await contract.methods
      .alreadyMintedPaid(_address)
      .call();
    return numberFreeMinted;
  } catch (error) {
    return error.message;
  }
};

//read number of allowlist mints a person has minted
export const readNumberAllowlistMinted = async (_address) => {
  //load smart contract
  let contract = await new web3.eth.Contract(contractABI, contractAddress);

  try {
    const numberFreeMinted = await contract.methods
      .alreadyMintedAllowlist(_address)
      .call();
    return numberFreeMinted;
  } catch (error) {
    return error.message;
  }
};

//mint free public
export const mintFreePublic = async (_address, _numberOfTokens) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: _address, // must match user's active address.
    data: window.contract.methods.freePublicMint(_numberOfTokens).encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        'Check out your transaction on Etherscan: https://etherscan.io/tx/' +
        txHash,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      status: 'Something went wrong: ' + error.message,
    };
  }
};

//mint paid public
export const mintPaidPublic = async (_address, _numberOfTokens) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  const valueToSend = (_numberOfTokens * 0.005).toString();

  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: _address, // must match user's active address.
    value: parseInt(web3.utils.toWei(valueToSend, 'ether')).toString(16),
    data: window.contract.methods.paidPublicMint(_numberOfTokens).encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        'Check out your transaction on Etherscan: https://etherscan.io/tx/' +
        txHash,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      status: 'Something went wrong: ' + error.message,
    };
  }
};

//mint allowlist
export const mintAllowlist = async (_address, _numberOfTokens, _maxAmount) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  //get merkle proof
  const proof = getProof(_address, _maxAmount.toString());

  // console.log(proof);

  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: _address, // must match user's active address.
    data: window.contract.methods
      .mintAllowlist(_numberOfTokens, proof, _maxAmount)
      .encodeABI(), //takes amount, merkleproof, and maxamount
  };

  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        'Check out your transaction on Etherscan: https://etherscan.io/tx/' +
        txHash,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      status: 'Something went wrong: ' + error.message,
    };
  }
};

//read number of candy a person has claimed
export const readNumberCandyClaimed = async (_address) => {
  //load smart contract
  let contract = await new web3.eth.Contract(contractABI, contractAddress);

  try {
    const numberCandyClaimed = await contract.methods
      .claimedCounter(_address)
      .call();
    return numberCandyClaimed;
  } catch (error) {
    return error.message;
  }
};

//claim candy
export const claimCandy = async (_address, _numberOfTokens, _maxAmount) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  //get merkle proof
  const proof = getProof(_address, _maxAmount.toString());

  // console.log(proof);

  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: _address, // must match user's active address.
    data: window.contract.methods
      .claimCandy(_numberOfTokens, proof, _maxAmount)
      .encodeABI(), //takes amount, merkleproof, and maxamount
  };

  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        'Check out your transaction on Etherscan: https://etherscan.io/tx/' +
        txHash,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      status: 'Something went wrong: ' + error.message,
    };
  }
};
